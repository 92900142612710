import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import ExternalPointSearch, { ListItem } from "@/store/external-point/search";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import ExternalPointUpdate from "@/store/external-point/update";
import { UpdateExternalPointRequest, SearchExternalPointRequest } from "@/api/external-point/request";


@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDialogDelete
  }
})
export default class list extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "外部ポイント";
  headingSub = "ExternalPoints";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "外部ポイント", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "external-points" }
    },
    { text: "ポイントカード一覧", disabled: true }
  ];

  // テーブルヘッダ
  tableHeaders = [
    { text: "会員ID", value: "id" },
    { text: "氏名", value: "name" },
    { text: "ポイントカード番号", value: "cardNumber" },
    {
      label: "紐付解除",
      text: "",
      value: "unlink",
      sortable: false
    },
    {
      label: "紐付変更",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  // 処理区分
  kbnUnLink: number = 1;
  kbnUpdateLink: number = 2;

  // ------------
  // 変動値
  // ------------
  // 検索入力オプション
  inputOptions = {
    shopId: Number(this.shopId),
    userId: null as number | null,
    userName: null as string | null
  } as SearchExternalPointRequest;

  // 検索テーブルオプション
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // 紐付更新用パラメータ
  updateParams = {
    shopId: Number(this.shopId),
    // 会員ID
    userId: null as number | null,
    // 処理区分
    kbn: null as number | null,
    // カード番号
    cardNumber: null as string | null
  } as UpdateExternalPointRequest;



  // ローディングステータス
  isLoading: boolean = false;
  // 紐付解除ダイアログ表示ステータス
  showDialog: boolean = false;
  // 紐付変更ダイアログ表示ステータス
  showUpdateLinkDialog: boolean = false;
  // ダイアログ表示氏名
  deletingName: string = '';
  // テーブルに表示するアイテムリスト
  items: ListItem[] = [];
  // 総件数
  totalCount: number = 0;



  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    return ExternalPointSearch.getItems;
  }

  /**
   * 総件数
   */
  get totalCountRecords() {
    return ExternalPointSearch.getTotalCount;
  }

  /**
   * 店舗名
   */
  get shopName() {
    return this.$route.query.shopName || "";
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {

    this.isLoading = true;
    await ExternalPointSearch.restore(this.inputOptions as SearchExternalPointRequest);
    const request = ExternalPointSearch.getSearchRequest;

    // 検索入力オプション
    request.userId && (this.inputOptions.userId = request.userId);
    request.userName && (this.inputOptions.userName = request.userName);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
    this.isLoading = false;
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ExternalPointSearch.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.tableOptions.page = 1;//検索後、1ページ目へ移動
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   */
  editClickCallback(item: ListItem) {
    this.showUpdateLinkDialog = true;

    this.updateParams.userId = Number(item.id);
    this.updateParams.cardNumber = item.cardNumber;
    this.updateParams.kbn = this.kbnUpdateLink;
  }

  /**
   * 検索処理
   */
  async search() {

    this.isLoading = true;
    const pointSearch = ExternalPointSearch.search(this.createRequest());
    await Promise.all([pointSearch]).then(() => {
      // UIDataTableコンポーネントに渡す
      this.items = this.tableItems;
      this.totalCount = this.totalCountRecords;

      this.isLoading = false;
    });

    // エラー出力
    if (!ExternalPointSearch.isSuccess) {
      await Flash.setErrorNow({ message: ExternalPointSearch.getMessage, showReloadButton: true } as ErrorAlert);
    }

  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchExternalPointRequest {

    const request = this.tableOptions as SearchExternalPointRequest;
    request.shopId = Number(this.shopId);

    if (this.inputOptions.userId) {
      request.userId = this.inputOptions.userId;
    } else {
      delete request.userId;
    }

    if (this.inputOptions.userName) {
      request.userName = this.inputOptions.userName;
    } else {
      delete request.userName;
    }

    return request;
  }

  /**
   * テーブル行の紐付解除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  unlinkClickCallback(item: ListItem) {
    this.showDialog = true;

    this.deletingName = item.name + "のポイントカード紐付情報";
    this.updateParams.userId = Number(item.id);
    this.updateParams.kbn = this.kbnUnLink;
    this.updateParams.cardNumber = item.cardNumber;
  }


  /**
   * ダイアログの紐付解除のコールバック
   */
  async doUnlinkOnDialog() {
    await Flash.clear();

    this.showDialog = false;
    this.isLoading = true;

    await ExternalPointUpdate.update(this.updateParams);

    // 結果出力
    if (ExternalPointUpdate.isSuccess) {
      await Flash.setSuccessNow({ message: "カード情報が削除されました。", consumePath: "" } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({ message: ExternalPointUpdate.getMessage, showReloadButton: true } as ErrorAlert);
    }
    this.isLoading = false;
  }


  async updateLink() {
    await Flash.clear();
    this.showUpdateLinkDialog = false;
    this.isLoading = true;

    await ExternalPointUpdate.update(this.updateParams);

    // 結果出力
    if (ExternalPointUpdate.isSuccess) {
      await Flash.setSuccessNow({ message: "カード情報の紐付が変更されました。", consumePath: "" } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({ message: ExternalPointUpdate.getMessage, showReloadButton: false } as ErrorAlert);
    }
    this.isLoading = false;
  }
}
