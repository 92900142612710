import service from "@/api/service";
import { SearchExternalPointResponse } from "@/api/external-point/response";
import { SearchExternalPointRequest } from "@/api/external-point/request";
import { UpdateExternalPointRequest } from "@/api/external-point/request";
import { CommonResponse } from "@/api/response";

/**
 * 外部ポイント店舗一覧・検索APIをコールします。
 * @param searchRequest 検索のリクエストボディ
 * @return SearchExternalPointResponse
 */
export async function search(searchRequest: SearchExternalPointRequest) {
  const response = await service.post("/search-external-point", searchRequest);
  return response.data as SearchExternalPointResponse;
}

/**
 * 外部ポイント紐付け更新APIをコールします。
 *
 * @param updateRequest 紐付け更新のリクエストボディ
 * @return CommonResponse
 */
export async function update(updateRequest: UpdateExternalPointRequest) {
  const response = await service.post("/update-external-point",updateRequest);
  return response.data as CommonResponse;
}
